import React from "react";
import useDeleteData from "../../hooks/useDeletData";
import { Link } from "react-router-dom";

//icons
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";

function WishCard({ t }) {
  const {
    error: errorDelete,
    loading: loadingDelete,
    data: dataDelete,
    deleteData,
  } = useDeleteData();

  const handleDelete = (id) => {
    if (window.confirm("er du sikker på at du vil slette?")) {
      deleteData("https://api.airtable.com/v0/appo7DIlw7hKpPrJD/wish/" + id, {
        Authorization: "Bearer keypJpuWWvXgrg8ka",
      });
      console.log(id);
    }
  };

  return (
    <div className="wishcard">
      <h4>I wish for:</h4>
      <p>{t.fields.Name}</p>

      <a href={t.fields.link} target="_blank">
        {" "}
        Find my wish here{" "}
        <div className="imgContainer">
          <img src={t.fields.img} alt={t.fields.Name}></img>
        </div>
      </a>
      <p className="date">
        {new Date(t.createdTime).toLocaleString("da-dk", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>

      <div className="edit">
        <Link to={"/wishlistEdit/" + t.id}>
          <button>
            {" "}
            <AiTwotoneEdit />
          </button>
        </Link>

        {/* arrowfucntione kommes på så onclick ikke køre flere gang kun når den faktisk bliver klikket på */}
        <button onClick={() => handleDelete(t.id)}>
          <AiTwotoneDelete />{" "}
        </button>
      </div>
    </div>
  );
}

export default WishCard;
