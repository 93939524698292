import React from "react";

function Titel({ headline }) {
  return (
    <>
      <h1>{headline}</h1>
    </>
  );
}

export default Titel;
