import React from "react";
import Titel from "../../components/Titel";
import useGetData from "../../hooks/useGetData";
import Loader from "../../components/loader";
import Error from "../../components/Error";
import WishCreate from "./wishCreate";
import { useEffect } from "react";
import WishCard from "./wishcard";

function Wishlist() {
  const { error, loading, data, getData } = useGetData();

  useEffect(() => {
    getData(
      "https://api.airtable.com/v0/appo7DIlw7hKpPrJD/wish?&view=Grid%20view",
      {
        Authorization: "Bearer keypJpuWWvXgrg8ka",
      }
    );
  }, []);

  return (
    <div>
      <Titel headline="My wishlist" />

      <div className="msgcontainer">
        {/* error */}
        {error && <Error />}

        {/* loading */}
        {loading && <Loader />}
      </div>

      <div className="toDo">
        <WishCreate />

        {data &&
          data.records.map((t) => (
            <div key={t.id} className="toDo-card">
              <WishCard t={t} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Wishlist;
