import React from "react";

function Todocard({ t }) {
  return (
    <div>
      <h4>To do:</h4>
      <p>{t.fields.Name}</p>
      <p className="task">{t.fields.todos}</p>
      <p className="date">
        {new Date(t.createdTime).toLocaleString("da-dk", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
    </div>
  );
}

export default Todocard;
