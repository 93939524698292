import React from "react";
import Titel from "../components/Titel";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div>
      <Titel headline="Project overview" />

      <div>
        <div>
          <Link to="/news">news</Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
