import { useState, useEffect } from "react";
import Titel from "../../components/Titel";
import usePostData from "../../hooks/usePostdata";
import useGetData from "../../hooks/useGetData";
import Error from "../../components/Error";
import Loader from "../../components/loader";

function WishCreate() {
  //hook til post/opret data
  const {
    error: postError,
    loading: PostLoading,
    data: PostData,
    postData,
  } = usePostData();

  //state til at rumme ny to do (fra input felt)
  const [newWish, setNewWish] = useState("");

  const [newWishLink, setNewWishLink] = useState("");

  const [imgLink, setImgLink] = useState("");

  useEffect(() => {
    if (PostData) setNewWish("");
    document.querySelector("form").reset();
  }, [PostData]);

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault(); //stop fra at reload hele side in submit

    let ny = {
      fields: {
        Name: newWish,
        link: newWishLink,
        img: imgLink,
      },
    };
    //send til hook der sender til API

    postData("https://api.airtable.com/v0/appo7DIlw7hKpPrJD/wish", ny, {
      Authorization: "Bearer keypJpuWWvXgrg8ka",
      "Content-Type": "application/json",
    });
  };

  return (
    <div className="toDo-card  wishcard">
      <Titel headline="Make a wish" />

      <form onSubmit={handleSubmit}>
        <label>
          {" "}
          Type in your wish
          <input
            type="text"
            placeholder="write your wish here"
            value={newWish}
            onInput={(e) => setNewWish(e.target.value)}
          ></input>
        </label>

        <label>
          {" "}
          Type in a link to your wish
          <input
            type="text"
            placeholder="post link here"
            value={imgLink}
            onInput={(e) => setImgLink(e.target.value)}
          ></input>
        </label>

        <label>
          {" "}
          Type in a link to your wish
          <input
            type="text"
            placeholder="post link here"
            value={newWishLink}
            onInput={(e) => setNewWishLink(e.target.value)}
          ></input>
        </label>

        {/* <label>
          Your Image File
          <input type="file" name="myImage" accept="image/*" />
        </label> */}

        <button type="submit">Send my wish to santa</button>
      </form>
    </div>
  );
}

export default WishCreate;
