import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/nav-kopi";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
import "./App.scss";
import Hobbies from "./pages/Hobbies";
import Words from "./pages/Words";
import Facts from "./pages/Facts";
import Weather1 from "./pages/weather/Weather1";
import Weather2 from "./pages/weather/Weather2";
import Weather3 from "./pages/weather/Weather3";
import ToDo from "./pages/airtable/ToDo";
import ToDoAdmin from "./pages/airtable/ToDoAdmin";
import Edit from "./pages/airtable/Edit";
import Nav from "./components/nav";
import News from "./pages/News";
import Species from "./pages/StarWars/Species";
import StarwarsPages from "./pages/StarWars/Starwars-pages";
import Starwars from "./pages/StarWars/Starwars";
import Wishlist from "./pages/wishlist/wishlist";
import WishEdit from "./pages/wishlist/WishEdit";

function App() {
  return (
    <Router>
      <Nav />

      <main>
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="/news" element={<News />}></Route>

          <Route path="/hobby" element={<Hobbies />}></Route>

          <Route path="/words" element={<Words />}></Route>

          <Route path="/facts" element={<Facts />}></Route>

          <Route path="/wishlist" element={<Wishlist />}></Route>
          <Route path="/wishlistEdit/:id" element={<WishEdit />}></Route>

          <Route path="/weather1" element={<Weather1 />}></Route>
          <Route path="/weather2" element={<Weather2 />}></Route>
          <Route path="/weather3" element={<Weather3 />}></Route>

          <Route path="/Species" element={<Species />}></Route>

          <Route path="/starwars-pages" element={<StarwarsPages />}></Route>

          <Route path="/starwars" element={<Starwars />}></Route>

          <Route path="/todo" element={<ToDo />}></Route>

          <Route path="/todoEdit/:id" element={<Edit />}></Route>

          <Route path="/todoAdmin" element={<ToDoAdmin />}></Route>

          <Route path="*" element={<Notfound />}></Route>
        </Routes>
      </main>
    </Router>
  );
}

export default App;
