import React from "react";
import Titel from "../../components/Titel";
import useGetData from "../../hooks/useGetData";
import Loader from "../../components/loader";
import Error from "../../components/Error";
import ToDoCreate from "./toDoCreate";
import { useEffect } from "react";
import Todocard from "../../components/Todocard";

function ToDo() {
  const { error, loading, data, getData } = useGetData();

  useEffect(() => {
    getData("https://api.airtable.com/v0/appYdrJIQqgZg8eGB/tblECrVoUhPApepSC", {
      Authorization: "Bearer " + "keypJpuWWvXgrg8ka",
    });
  }, []);

  return (
    <div>
      <Titel headline="To do" />

      <div className="msgcontainer">
        {/* error */}
        {error && <Error />}

        {/* loading */}
        {loading && <Loader />}
      </div>

      <div className="toDo">
        <ToDoCreate />

        {data &&
          data.records.map((t) => (
            <div key={t.id} className="toDo-card">
              <Todocard t={t} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ToDo;
