import React from "react";
import Titel from "../../components/Titel";
import { useParams } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import { useState, useEffect } from "react";
import Error from "../../components/Error";
import Loader from "../../components/loader";
import usePatchData from "../../hooks/usePatchData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function WishEdit() {
  const { id } = useParams();

  //henter to do der skal rettes
  const { error, loading, data, getData } = useGetData();
  //henter kategorier

  const {
    error: patchError,
    loading: PatchLoading,
    data: patchData,
    PatchData,
  } = usePatchData();

  const [updatet, setUpdatet] = useState();
  const [updatetLink, setUpdatetLink] = useState();
  const [updatetImg, setUpdatetImg] = useState();

  const navigate = useNavigate();

  //henter den to do der skal rettes
  useEffect(() => {
    getData("https://api.airtable.com/v0/appo7DIlw7hKpPrJD/wish/" + id, {
      Authorization: "Bearer keypJpuWWvXgrg8ka",
      "Content-Type": "application/json",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let updateMsg = {
      fields: {
        Name: updatet,
        link: updatetLink,
        img: updatetImg,
      },
    };

    PatchData(
      "https://api.airtable.com/v0/appo7DIlw7hKpPrJD/wish/" + id,

      updateMsg,
      {
        Authorization: "Bearer keypJpuWWvXgrg8ka",
        "Content-Type": "application/json",
      }
    );

    setTimeout(() => {
      navigate("/wishlist", { replace: true });
    }, 2000);
  };

  return (
    <div>
      <div className="msgcontainer">
        {/*Hvis der er error eller errorDelete */}
        {error && <Error />}

        {/* loading */}
        {loading && <Loader />}
      </div>

      {data && (
        <div>
          <div className="edit-head">
            <Link to="/wishlist"> Back to my wishes</Link>
            <Titel headline="Change your wish" />
          </div>

          <form onSubmit={handleSubmit}>
            <label>
              {" "}
              Update your wish:
              <input
                defaultValue={data.fields.Name}
                type="text"
                onInput={(e) => setUpdatet(e.target.value)}
              ></input>
            </label>

            {/* choose Link */}
            <label>
              {" "}
              Update your link:
              <input
                defaultValue={data.fields.link}
                type="text"
                onInput={(e) => setUpdatetLink(e.target.value)}
              ></input>
            </label>

            <label>
              {" "}
              Update your link:
              <input
                defaultValue={data.fields.img}
                type="text"
                onInput={(e) => setUpdatetImg(e.target.value)}
              ></input>
            </label>

            <button type="submit">Gem rettelse</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default WishEdit;
