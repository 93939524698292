import React from "react";
import { Link } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <li>
        <Link to="/" className="navbar-brand">
          <img className="logo" src="../assets/logo.svg" alt=""></img>
        </Link>
      </li>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/news" className="nav-link">
              News
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/hobby" className="nav-link">
              Hobby
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/words" className="nav-link">
              Word
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/facts" className="nav-link">
              Facts
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/todo" className="nav-link">
              To do
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/wishlist" className="nav-link">
              Wishlist
            </Link>
          </li>

          {/* dropdown btn */}

          <DropdownButton id="dropdown-basic-button" title="Weather">
            <Link className="dropdown-item" to="/weather1">
              Weather 1
            </Link>
            <Link className="dropdown-item" to="/weather2">
              Weather 2
            </Link>
            <Link className="dropdown-item" to="/weather3">
              Weather 3
            </Link>
          </DropdownButton>

          <DropdownButton id="dropdown-basic-button" title="Starwars">
            <Link to="/species" className="dropdown-item">
              Species
            </Link>

            <Link to="/starwars-pages" className="dropdown-item">
              pages
            </Link>

            <Link to="/starwars" className="dropdown-item">
              Starwars
            </Link>
          </DropdownButton>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
